import axios from "axios";
import { api } from "../constants/urls";
import { useToastifyPromises } from "../helpers/toastifyHelper";

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useTripsApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   1.get trips
  const getTripsReq = ({
    rider_id = "",
    driver_id = "",
    vehicle_id = "",
    business_id = "",
    ride_status = "",
    ride_class = "",
    ride_type = "",
    page = 1,
    item_per_page = 50,
    start_date = "",
    end_date = "",
    component = "",
    q = "",
    request_lga = "",
    request_area = "",
  }: any) => {
    return axios.get(
      api.trips +
        `/admin/trips/?rider_id=${rider_id}&vehicle_id=${vehicle_id}&q=${q}&driver_id=${driver_id}&business_id=${business_id}&request_lga=${request_lga}&request_area=${request_area}&ride_type=${ride_type}&ride_class=${ride_class}&ride_status=${ride_status}&start_date=${start_date}&end_date=${end_date}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };
  const getTripsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getTripsReq(body) });

  //   2.get trips details
  const getTripsDetailsReq = ({ trip_id = "", ride_status = "" }: any) => {
    return axios.get(api.trips + `/admin/trips/${trip_id}?ride_status=${ride_status}`);
  };
  const getTripsDetailsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getTripsDetailsReq(body) });

  //3. Get rider's ratings
  const getTripRatingsReq = ({ user_type = "", auth_id = "", page = 1, item_per_page = 50, component = "" }: any) => {
    return axios.get(api.trips + `/admin/ratings/?auth_id=${auth_id}&user_type=${user_type}&page=${page}&item_per_page=${item_per_page}&component=${component}`);
  };
  const getTripRatingsToast = (body?: any) => toastifyPromises.get({ asyncFunction: getTripRatingsReq(body) });

  //   1.get trips Performance
  const getTripsPerformanceReq = ({ start_date = "", end_date = "", component = "", request_type = "", item_per_page = 50, page = "", target_amount = "" }: any) => {
    return axios.get(api.trips + `/admin/trip-performance/?component=${component}&item_per_page=${item_per_page}&page=${page}&request_type=${request_type}&start_date=${start_date}&end_date=${end_date}&target_amount=${target_amount}`);
  };
  const getTripsPerformanceToast = (body?: any) => toastifyPromises.get({ asyncFunction: getTripsPerformanceReq(body) });

  return {
    getTripsToast,
    getTripsDetailsToast,
    getTripRatingsToast,
    getTripsPerformanceToast,
  };
};
