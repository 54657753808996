import { useContext } from 'react';
import { AppPrefrenceContext } from '../../contexts/AppPrefrenceContext';

type Props = {
  onClick?: () => void;
  tag?: string;
};

const ExportComponent = (props: Props) => {
  const { deleteModalConfig } = useContext(AppPrefrenceContext);

  const { onClick, tag } = props;
  return (
    <div
      onClick={() =>
        deleteModalConfig({
          firstText: `Are you sure you want to Export File?`,
          secondText: `This will send the excel file to your email`,
          callback: () => (onClick ? onClick() : null),
          type: 'id-verification',
          actionButton: 'blue',
        })
      }
      className='cursor-pointer d-flex align-items-center'
    >
      <img src='/svgs/export.svg' />
      <div>{tag}</div>
    </div>
  );
};

export default ExportComponent;
