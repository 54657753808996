
export const operationsLinks = {
  title: "Operations",
  icon: <img alt="icon" src="/svgs/icons/operations.svg" />,
  link: "operations",
  subNavs: [
    {
      title: "Overview",
      link: "/operations/",
    },
    // {
    //   title: "Business Perf.",
    //   link: "/operations/business-performance",
    // },
    // {
    //   title: "Trip Perf.",
    //   link: "/operations/trip-performance",
    // },
    // {
    //   title: "Vehicle Perf.",
    //   link: "/operations/vehicle-performance",
    // },
    // {
    //   title: "Revenue Perf.",
    //   link: "/operations/revenue-performance",
    // },
    // {
    //   title: "Driver Perf.",
    //   link: "/operations/driver-performance",
    // },
    // {
    //   title: "Driver Watchlist",
    //   link: "/operations/driver-wathclist",
    // },
    {
      title: "Vehicle Perf.",
      link: "/operations/vehicle-performance",
    },
    // {
    //   title: "Infractions",
    //   link: "/operations/infractions",
    // },
  ],
};
