import { useContext } from 'react';
import { toast } from 'react-toastify';
import { LoadingContext } from '../contexts/LoadingContext';

export const toastify = ({ type, message }: any) =>
  toast(message, {
    type: type,
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });

export const useToastifyPromises = () => {
  const { setPostLoading, setGetLoading }: any = useContext(LoadingContext);

  const getRequest = async ({ asyncFunction }: any) => {
    setGetLoading(true);

    try {
      const res = await asyncFunction;
      // if (res.data.status !== "ok") {
      if (res.data.status === 'error') {
        toast(res.data.msg, {
          type: 'error',
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          isLoading: false,
        });
      }
      setGetLoading(false);
      return res?.data;
    } catch (err: any) {
      err.response.data.code !== 401 &&
        toast('Network error', {
          type: 'error',
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
          isLoading: false,
        });
      setGetLoading(false);
      throw err;
    }
  };

  const otherReq = async ({ asyncFunction, pendingMsg, SuccessMsg }: any) => {
    setPostLoading(true);
    const id = pendingMsg && toast.loading(pendingMsg);
    try {
      const res = await asyncFunction;

      // Check if res and res.data exist before accessing properties
      if (!res || !res.data) {
        throw new Error('Something went wrong, try again');
      }

      if (res.data.status === 'error') {
        toast.update(id, {
          render: res?.data?.msg || 'An error occurred',
          type: 'error',
          isLoading: false,
          autoClose: 2000,
        });
      } else {
        toast.update(id, {
          render: res?.data?.message || SuccessMsg || 'Operation successful',
          type: 'success',
          isLoading: false,
          autoClose: 2000,
        });
      }

      setPostLoading(false);
      return res;
    } catch (err: any) {
      // Handle 406 or other server errors more gracefully
      let errorMessage = 'Something went wrong';

      if (err?.response) {
        const status = err.response.status;

        if (status === 406) {
          errorMessage = 'The request was not acceptable (406 error)';
        } else {
          errorMessage = err.response?.data?.message || err.message;
        }
      } else {
        errorMessage = err.message || errorMessage;
      }

      toast.update(id, {
        render: errorMessage,
        type: 'error',
        isLoading: false,
        autoClose: 2000,
      });

      setPostLoading(false);
      throw err;
    }
  };

  return {
    get: getRequest,
    post: otherReq,
    patch: otherReq,
    put: otherReq,
    delete: otherReq,
  };
};
