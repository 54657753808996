import axios from 'axios';
import { api } from '../constants/urls';
import { useToastifyPromises } from '../helpers/toastifyHelper';

// import {AuthActionSuccess, LogoutAction} from "../actions/AuthActions";

export const useWalletApiService = () => {
  const toastifyPromises = useToastifyPromises();

  //   3.get wallet
  const getWalletReq = ({
    page = 1,
    auth_id = '',
    vehicle_id = '',
    user_type = '',
    q = '',
    status = '',
    wallet_type = '',
    item_per_page = 50,
    start_date = '',
    end_date = '',
    component = '',
  }: any) => {
    return axios.get(
      api.wallet +
        `/admin/wallet-transaction/?wallet_type=${wallet_type}&q=${q}&vehicle_id=${vehicle_id}&user_type=${user_type}&auth_id=${auth_id}&status=${status}&start_date=${start_date}&end_date=${end_date}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };
  const getWalletToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getWalletReq(body) });

  const getUserWalletStatsReq = ({
    user_type = '',
    wallet_type = '',
    start_date = '',
    end_date = '',
    component = '',
  }: any) => {
    return axios.get(
      api.wallet +
        `/admin/user-wallet-stat/?user_type=${user_type}&wallet_type=${wallet_type}&start_date=${start_date}&end_date=${end_date}&component=${component}`
    );
  };
  const getUserWalletStatsToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getUserWalletStatsReq(body) });

  const getWalletDvaReq = ({ vehicle_id = '', auth_id = '' }: any) => {
    return axios.get(
      api.wallet +
        `/admin/dva-account?vehicle_id=${vehicle_id}&auth_id=${auth_id}`
    );
  };
  const getWalletDvaToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getWalletDvaReq(body) });

  const getTripPaymentReq = ({
    page = 1,
    status = '',
    payment_method = '',
    rider_id = '',
    driver_id = '',
    vehicle_id = '',
    q = '',
    user_type = '',
    item_per_page = 50,
    start_date = '',
    end_date = '',
    component = '',
    request_type = '',
  }: any) => {
    return axios.get(
      api.wallet +
        `/admin/trip-transaction/?q=${q}&user_type=${user_type}&status=${status}&payment_method=${payment_method}&request_type=${request_type}&rider_id=${rider_id}&driver_id=${driver_id}&vehicle_id=${vehicle_id}&start_date=${start_date}&end_date=${end_date}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };

  const getTripPaymentToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getTripPaymentReq(body) });

  //    trip payment details
  const getTripPaymentDetailsReq = ({ payment_id = '' }: any) => {
    return axios.get(api.wallet + `/admin/trip-transaction/${payment_id}`);
  };
  const getTripPaymentDetailsToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getTripPaymentDetailsReq(body) });

  //service payment
  const getServicePaymentReq = ({
    page = 1,
    q = '',
    item_per_page = 50,
    start_date = '',
    end_date = '',
    payment_type = '',
    status = '',
    component = '',
  }: any) => {
    return axios.get(
      api.wallet +
        `/admin/service-payment/?q=${q}&payment_type=${payment_type}&start_date=${start_date}&status=${status}&end_date=${end_date}&page=${page}&item_per_page=${item_per_page}&component=${component}`
    );
  };

  const getServicePaymentToast = (body?: any) =>
    toastifyPromises.get({ asyncFunction: getServicePaymentReq(body) });

  //update transfer payment status
  const updatePaymentStatusReq = (body: any) =>
    axios.patch(api.wallet + `/admin/trip-transaction/${body?.payment_id}`, body);
  const updatePaymentStatusToast = (body?: any) =>
    toastifyPromises.patch({
      asyncFunction: updatePaymentStatusReq(body),
      pendingMsg: 'Approving payment... ',
      SuccessMsg: 'Payment approved',
    });


  return {
    getWalletToast,
    getUserWalletStatsToast,
    getWalletDvaToast,
    getTripPaymentToast,
    getTripPaymentDetailsToast,
    getServicePaymentToast,
    updatePaymentStatusToast,
  };
};
